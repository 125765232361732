<template>
    <div class="container-fluid">
        <Navbar active="creator" />
        <div class="overflow-gidden p-0 cover">
            <div class="row">
                <div class="col-lg-12"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from "@/components/layout/NavbarClient.vue";

export default {
    data: function () {
        return {};
    },
    components: {
        Navbar,
    },
    async beforeMount() {},
    watch: {},
    methods: {},
};
</script>
<style></style>
